@import "../../../Components/Themes/Font.scss";

.steps-form {
  width: 100vw;
  .step {
    @extend .size3-5-2-B;
    color: var(--shade-0);

    > span strong {
      font-weight: 700 !important;
    }
  }
  &.organization-form {
    > div {
      width: 581px !important;
    }
    .form {
      .inner {
        .group {
          display: flex;
          width: 100%;
          gap: var(--space-14);
          > div {
            width: 100% !important;
          }
          // input {
          //   &:first-child {
          //     width: fit-content !important;
          //     margin: 0 auto !important;
          //   }
          // }
        }
      }
    }
  }
  // > div {
  //   width: 411px !important;
  // }
  .login {
    @extend .size2-R;
    text-align: center;
    .login-btn {
      @extend .size2-5-2-B;
      color: var(--primary);
      text-decoration: none;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    .inner {
      display: flex;
      flex-direction: column;
      gap: 14px;
      .requirement-list {
        @extend .size3-R;
        color: var(--shade-0);
        display: flex;
        gap: 14px;
        align-items: center;
        text-decoration: none;
        list-style: none;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
        @extend .size1-5-R;
        color: var(--shade-100);
        li {
          display: flex;
          align-items: center;
          gap: 4px;
          text-decoration: none;
          list-style-type: none;
          .icon {
            display: flex;
            padding: 3px;
            border-radius: 4px;
            background: var(--shade-150);
            min-width: 15.14px;
            min-height: 15.14px;
            width: 15.14px;
            height: 15.14px;
            cursor: pointer;
            &.checked {
              border: 1px solid var(--awa-green);
              background: var(--shade-400);
            }
          }
        }
      }
      .forgot-password-btn {
        @extend .size3-R;
        color: var(--shade-100) !important;
        background: none !important;
        text-decoration: underline !important;
        border: none !important;
        outline: none !important;
        width: fit-content;
        cursor: pointer;
      }
      .title {
        @extend .size5-5-1-S;
        color: var(--shade-0);
      }
      > div,
      div > div {
        width: 100% !important;
      }
      .info {
        @extend .size3-R;
        color: var(--shade-100);
        a {
          text-decoration: underline !important;
          font-weight: 600 !important;
          color: var(--shade-0);
        }
        button {
          @extend .size2-R;
          text-decoration: underline !important;
          font-weight: 600 !important;
          color: var(--shade-0);
          border: none;
          outline: none;
          background: none;
          cursor: pointer;
        }
      }
      .pins {
        display: flex;
        gap: var(--space-14);
      }
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-4);

      > span {
        @extend .size1-L;
        color: var(--shade-0);
      }
      > div {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
.checkbox-container {
  display: flex;
  gap: 14px;
  align-items: flex-start;
  width: 100%;
  .icon {
    display: flex;
    padding: 3px;
    border-radius: 4px;
    background: var(--shade-150);
    min-width: 15.14px;
    min-height: 15.14px;
    cursor: pointer;
    &.checked {
      border: 1px solid var(--awa-green);
      background: var(--shade-400);
    }
  }
  p {
    @extend .size3-R;
    // max-width: 553px;
    // min-width: 553px;
    color: var(--shade-100);
    &.active {
      color: var(--shade-0);
    }
  }
}

.last-step {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  .title {
    @extend .size5-5-1-S;
    color: var(--shade-0);
  }
  .middle-span {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 14px;
  }
  .info {
    @extend .size2-R;
    color: var(--shade-100);
    text-align: center;
  }
  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-4);
    .progress {
      @extend .size1-5-R;
      color: var(--shade-100) !important;
    }
    > span {
      @extend .size1-L;
      color: var(--shade-0);
    }
    > div {
      width: 100%;
      justify-content: center;
    }
  }
}

.dropdown-container {
  position: relative;
}

.otp-inputs {
  width: 9px !important;
  margin: 0 auto !important;
  text-align: center !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.prompt {
  position: fixed;
  top: 110px;
  display: flex;
  padding: 14px;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--shade-prompt);
  z-index: 7;

  &-left {
    @extend .size2-R;
    font-weight: 400 !important;
    line-height: normal !important;
    color: var(--shade-0);
    display: flex;
    align-items: center;
    gap: 10px;
    > svg {
      min-width: 20px;
      min-height: 20px;
    }
  }
  .verify-phone {
    @extend .size3-R;
    color: white;
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #f4a622;
    border: none;
    text-decoration: none;
    text-wrap: nowrap !important;
  }
}

@media screen and (max-width: 530px) {
  .group {
    flex-direction: column !important;
  }
}
