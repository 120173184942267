.dropdown {
  display: flex;
  flex-direction: column;
  gap: 4;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  position: absolute;
  background: var(--shade-200);
  top: 49px;
  left: 0;
  z-index: 100;
  cursor: pointer;
  padding: 14px !important;
  max-height: 202px;
  overflow-y: auto;
  box-shadow: 0px 24px 48px 0px rgba(130, 124, 241, 0.07);
  &::-webkit-scrollbar {
    width: 6px;
    height: 4rem;
    background: var(--shade-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--shade-400);
    border-radius: 5px;
    height: 4rem;
    cursor: pointer;
  }
  li {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 4px;
    width: 100%;
    // height: 35px;
    background: var(--shade-200);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--shade-100);
    min-height: 35px;
    // padding-left: calc(26px);
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:hover {
      background: var(--shade-300);
    }
  }
  .selected {
    background: var(--shade-300);
  }
  &-icon-container {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 90 !important;
}
