@import "../../../Components/Themes/Font.scss";

.edit-business {
  min-height: 100vh;
  min-width: 100%;

  .title-main {
    @extend .size5-5-1-S;
    line-height: normal !important;
    color: var(--shade-0);
  }

  .verification-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 14px;
    border-radius: 8px;
    background: rgba(0, 186, 128, 0.05);

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .identity {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .id {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .identity,
      .id {
        @extend .size2-R;
        line-height: normal !important;
        font-weight: 400 !important;
        color: var(--shade-0);
      }
    }

    .virtual-nin-prompt {
      display: flex;
      padding: 10px;
      flex-direction: column;
      gap: 4px;
      border-radius: 9px;
      background: var(--shade-400);
      width: 100%;
      color: var(--shade-0) !important;
      transition: all 0.3s ease-in-out;

      &__header {
        @extend .size3-R;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 14px;
        > p,
        li {
          @extend .size2-R;
          font-size: 12px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          text-decoration: none;
          list-style-type: none;
        }
      }
    }
  }
}

.product-btn {
  @extend .size2-R;
  color: var(--shade-0);
  font-weight: 700 !important;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
    background: var(--primary);
  }
}
.lazy {
  @extend .size2-R;
  color: var(--shade-0);
  display: flex;
  padding: 40px 176px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  width: 100%;
  background: var(--completed-20);
  filter: blur(2px);
  animation: loaderSwipeAnim 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
    background: var(--primary);
  }
}

.verify_btn {
  @extend .size2-R;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  color: var(--fixed-white);
  padding: 8px 10px;
  border-radius: 4px;
  background: var(--awa-green);
  border: none;
}

.error-msg {
  @extend .size2-R;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 100% !important;
  color: var(--error);
}

.verifying-id-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 361px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 21px;

  &__header {
    @extend .size5-5-1-S;
    color: var(--shade-0);
  }
  .progress {
    @extend .size1-5-R;
    color: var(--shade-100) !important;
    margin-top: -17px;
  }
}

@keyframes loaderSwipeAnim {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@media screen and (max-width: 487px) {
  .verifying-id-container {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

@media screen and (max-width: 411px) {
  .product-btn {
    padding: 40px 0 !important;
  }
}
