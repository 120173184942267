@import "../../../Components/Themes/Font.scss";

.cart {
  width: 100%;
  background: var(--shade-400);
  min-height: 100vh;
  min-width: 100%;
  padding: 40px 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    @extend .size4-R;
    color: var(--shade-100);
    font-weight: 600 !important;
    line-height: 28px !important;
  }
  > li {
    @extend .size3-R;
    color: var(--shade-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .price {
      @extend .size4-R;
      font-weight: 400 !important;
      line-height: 28px !important;
      color: var(--shade-0);
      .bold {
        font-weight: 700 !important;
      }
    }
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @extend .size5-R;
  color: var(--shade-0);
  font-weight: 400 !important;
  line-height: 28px !important;
  .total {
    @extend .size5-R;
    color: var(--shade-0);
    font-weight: 400 !important;
    line-height: 36px !important;

    &-bold {
      font-weight: 700 !important;
    }
  }
}
