@import "../../Themes/Font.scss";

.avatar-chain {
  position: relative;
  width: fit-content;
  height: 50px;
  display: flex;
  align-items: center;
}
.others {
  @extend .size1-5-1-M;
  color: var(--shade-0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: var(--completed);
  margin-left: -10px;
}
.images {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50px;
  object-fit: cover;
  &:nth-child(2) {
    margin-left: -20px;
  }
  &:nth-child(3) {
    margin-left: -20px;
  }
  &:nth-child(4) {
    margin-left: -20px;
  }
}

@media screen and (max-width: 455px) {
  .avatar-chain {
    height: 24px;
    .images,
    .others {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }
  }
}
