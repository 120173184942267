:root {
  --space-40: calc(100vw * 0.02777777778);
  --space-24: calc(100vw * 0.01666666666666667);
  --space-14: calc(100vw * 0.00972222222222222);
  --space-10: calc(100vw * 0.00694444444444444);
  --space-8: calc(100vw * 0.00555555555555556);
  --space-4: calc(100vw * 0.00277777777777778);
}

.content-0 {
  display: flex;
  gap: 0;
  width: 100%;
  padding: 0px !important;
  background-color: var(--shade-400);
}

.sidebar-dummy {
  width: 21.66666667%;
  min-width: 312px;
}

.sidebar-content {
  width: 21.66666667%;
}
.main-body-content {
  // position: relative;
  width: calc(100% - 21.66666667%);
  min-height: 100vh;
  max-height: 100vh;
  padding-bottom: 50px;
  overflow-y: auto;
  transition: all 0.8s ease-in-out;
  .right-side-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--space-24);
    margin: 0px 24px;
    align-content: center;
    justify-content: center;
    .grid-12 {
      grid-column-start: 1;
      grid-column-end: -1;
    }
    .grid-8 {
      grid-column: span 8;
    }
    .grid-8 {
      grid-column: span 8;
    }
    .grid-4 {
      grid-column: span 4;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--space-24);
    align-content: center;
    justify-content: center;
    .grid-12 {
      grid-column-start: 1;
      grid-column-end: -1;
    }
    .grid-8 {
      grid-column: span 8;
    }
    .grid-6 {
      grid-column-start: 6;
      grid-column-end: -1;
    }
    .grid-5 {
      grid-column-start: 1;
      grid-column-end: 6;
    }
    .grid-4 {
      grid-column: span 4;
    }
  }
}
