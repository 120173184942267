@import "./Components/Themes/Theme.scss";
@import "./Components/Themes/Font.scss";
@import "./styles/general.scss";

.App {
  background: var(--shade-400) !important;
}

.theme-toggle {
  position: fixed;
  right: 24px;
  bottom: 24px;
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--shade-300);
  box-shadow: 0px 24px 48px 0px rgba(130, 124, 241, 0.15);
  border: none;
  cursor: pointer;
  z-index: 200;
}

button {
  cursor: pointer;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.half-width-form {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  > div {
    width: 47%;
    // max-width: 222.5px !important;
    > div {
      width: 100%;
    }
  }
}

.form-title-main {
  @extend .size5-5-1-S;
  line-height: normal !important;
  color: var(--shade-0);
}

.markdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &.triple-height {
    height: 351px;
    min-height: 351px;
  }
}

.copy_float {
  position: absolute;
  top: -32px;
  left: -60px;
  display: inline-block;
  padding: 5px 10px;
  background-color: var(--shade-200);
  color: var(--shade-0);
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 11px;
  margin-top: 10px;
}
.copy_float::after {
  content: "";
  position: absolute;
  bottom: -8px;
  right: 0;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent var(--shade-200) transparent;
  transform: rotate(220deg) !important;
}

.description-long {
  width: 100%;
  height: 162px;
  > label {
    @extend .size2-R;
    color: var(--shade-100);
  }
  > textarea {
    @extend .size2-R;
    color: var(--shade-0);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    background: var(--shade-200);
    border: none;
    transition: all 0.3s ease;
    width: 100% !important;
    height: 83%;
    max-height: 83%;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 8px;
    padding: 14px 16px;
    resize: none;
    outline: none;
  }
}

.otp-inputs {
  > input {
    text-align: center !important;
  }
}

.required-label {
  position: relative;
  &::after {
    position: relative;
    content: "*";
    color: var(--error);
  }
}

.prompt {
  position: fixed;
  top: 110px;
  display: flex;
  padding: 14px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: var(--shade-prompt);
  z-index: 5;

  &-left {
    @extend .size2-R;
    font-weight: 400 !important;
    line-height: normal !important;
    color: var(--shade-0);
    display: flex;
    align-items: center;
    gap: 10px;
    > svg {
      min-width: 20px;
      min-height: 20px;
    }
  }
  .verify-phone {
    @extend .size3-R;
    color: white;
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #f4a622;
    border: none;
    text-decoration: none;
    text-wrap: nowrap !important;
  }
}

.error-message-prompt {
  @extend .size1-5-R;
  color: var(--error);
  margin-top: -7px;
}

.title-main {
  @extend .size5-5-1-S;
  line-height: normal !important;
  color: var(--shade-0);
}

.product-btn {
  @extend .size2-R;
  color: var(--shade-0);
  display: flex;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    flex-shrink: 0;
    background: var(--primary);
  }
}

.product-btn-2 {
  @extend .size2-R;
  color: var(--shade-400);
  display: flex;
  padding: 7px 0px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 8px;
  background: var(--shade-0);
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  border: none;
  // &:hover {
  //   background: var(--shade-200);
  // }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    flex-shrink: 0;
    background: var(--primary);
  }
}

.checkbox-container {
  display: flex;
  gap: 14px;
  align-items: flex-start;
  width: 100%;
  .icon {
    display: flex;
    padding: 3px;
    border-radius: 4px;
    background: var(--shade-150);
    min-width: 15.14px;
    min-height: 15.14px;
    cursor: pointer;
    &.checked {
      border: 1px solid var(--awa-green);
      background: var(--shade-400);
    }
  }
  p {
    @extend .size3-R;
    // max-width: 553px;
    // min-width: 553px;
    color: var(--shade-100);
    &.active {
      color: var(--shade-0);
    }
  }
}

@media screen and (max-width: 1000px) {
  * {
    cursor: default !important;
  }
}

@media screen and (max-width: 620px) {
  .half-width-form {
    flex-direction: column;
    > div {
      width: 100% !important;
      max-width: 100% !important;
      > div {
        width: 100% !important;
      }
    }
  }
}
