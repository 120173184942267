@import "../../../Themes/Font.scss";

.product-card {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--shade-400);
  width: 100%;

  .left {
    display: flex;
    width: 220.5px;
    max-width: 220.5px;
    align-items: center;
    gap: 20px;

    .figure {
      width: 50px;
      height: 50px;
      overflow: hidden;
    }
    &-image {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      border-radius: 9px;
      object-fit: cover;
    }

    &-details {
      @extend .size1-5-1-M;
      color: var(--shade-0);
      display: flex;
      width: 150.5px;
      flex-direction: column;
      gap: 5px;
      flex-shrink: 0;

      .label {
        @extend .size0-5-S;
        color: var(--shade-100);
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 20px;

    &-progress-container {
      @extend .size0-5-S;
      color: var(--shade-0);
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 119px;
    }

    &-menu-btn {
      position: relative;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      border-radius: 8px;

      &:hover {
        background-color: var(--shade-300);
      }
    }
  }
}

@media screen and (max-width: 622px) {
  .product-card {
    flex-direction: column;
    gap: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    .left {
      width: 100%;
      justify-content: space-between;
      min-width: 100%;

      &-details {
        align-items: flex-end;
        width: fit-content;
      }
      .label {
        width: fit-content;
      }
    }
    .right {
      justify-content: space-between;
      min-width: 100%;
      > div {
        width: 100%;
      }
    }
  }
}
