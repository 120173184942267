@import "../../../Components/Themes/Font.scss";

.sales {
  width: 100%;
  .search {
    display: flex;
    padding: 14px;
    gap: 10px;
    align-items: center;
    border-radius: 68px;
    border: 1px solid var(--shade-300);
    background: var(--shade-200);

    .input {
      @extend .size2-R;
      color: var(--shade-0);
      width: 100%;
      background: transparent;
      border: none;
      outline: none;

      &::placeholder {
        @extend .size2-R;
        color: var(--shade-100) !important;
      }
    }
  }
  .end {
    @extend .size0-5-S;
    color: var(--shade-100) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin: 0 auto;
  }
}
