@import "./Font.scss";
@import "./Grid.scss";

* {
  box-sizing: border-box;
}

:root {
  --primary: #1ebef1;
  --primary-20: rgba(30, 190, 241, 0.2);
  --completed: #827cf1;
  --completed-20: rgba(130, 124, 241, 0.2);
  --success: #85d7b9;
  --error: #fb5158;
  --awa-green: #00ba80;
  --warning: #ff9f0a;
  --orange: #ff9f0a;
  --fixed-white: #ffffff;
  --fixed-black: black;
  [data-theme="dark"] {
    --shade-0: #fff;
    --shade-10: rgba(123, 125, 127, 0.05);
    --shade-50: #f4f5f7;
    --shade-100: #7b7d7f;
    --shade-150: rgba(123, 125, 127, 0.2);
    --shade-200: #1d1f20;
    --shade-300: #101213;
    --shade-400: #0a0a0a;
    --shade-prompt: #22190c;
    --shade-green-prompt: #1c2522;
  }

  [data-theme="light"] {
    --shade-0: #101213;
    --shade-10: rgba(123, 125, 127, 0.05);
    --shade-50: rgba(123, 125, 127, 0.1);
    --shade-100: #7b7d7f;
    --shade-150: #dfdfdf;
    --shade-200: #ebebeb;
    --shade-300: #f4f5f7;
    --shade-400: #ffffff;
    --shade-prompt: #fef5e8;
    --shade-green-prompt: #f2fbf8;
  }
}
