@import "../../Themes/Font.scss";
:root {
  --full-width: 100%;
}
.Search {
  position: absolute;
  top: 47px;
  left: 0;
  z-index: 100;
  padding: 14px;
  width: calc(var(--full-width));
  max-width: 500px;
  //might remove later
  background-color: var(--shade-200) !important;
  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: var(--full-width);
    overflow-y: auto;
    max-height: 300px;
    transition: all 0.5s ease;
    height: auto;
    cursor: pointer;
    &::-webkit-scrollbar {
      width: 5px;
      height: 4rem;
      background: var(--shade-200);
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--shade-400);
      border-radius: 5px;
      cursor: pointer;
      height: 4rem;
    }
    .fixed {
      width: calc(var(--full-width));
      position: sticky;
      top: 0;
      background-color: var(--shade-200);
      div {
        display: flex;
        align-items: center;
        padding: 11px 14px;
        margin: 0 !important;
        gap: 10px;
        width: var(--full-width);
        background: var(--shade-400);
        border: 1px solid var(--shade-300);
        border-radius: 8px;
        width: 100%;
        input {
          @extend .size2-R;
          width: 100%;
          border: none;
          outline: none;
          background-color: var(--shade-400);
          /* identical to box height */

          /* LightGrey */

          color: var(--shade-100);
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: var(--full-width);
      max-width: var(--full-width);
      span {
        max-width: fit-content;
        max-height: fit-content;
      }
      li {
        @extend .size2-R;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        color: var(--shade-100);
        cursor: pointer;
        width: var(--full-width);
        max-width: var(--full-width);
        text-overflow: ellipsis !important;

        &:hover {
          background-color: var(--shade-400);
          // background-color: var(--shade-300);
          color: var(--shade-0);
          transition: all 0.5s ease;
        }
        .country_code {
          width: max-content !important;
          padding: 8px 4px;
          display: block;
        }
        .details {
          display: flex;
          width: -webkit-fill-available;
          flex-direction: row;
          align-items: center;
          gap: 14px;
          min-width: 0;
          padding: 8px 4px;
          p {
            max-width: 100%;
            text-overflow: ellipsis;
            max-height: 18px;
            overflow: hidden;
            white-space: nowrap !important;
          }
        }
      }
    }
    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      display: none;
    }
  }
}

.Search.empty {
  .inner {
    max-height: 198px !important;
    height: fit-content;
    min-height: fit-content;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 90 !important;
  cursor: pointer;
}
