@import "../../Themes/Font.scss";

.product-card {
  width: 238.5px;
  max-width: 238.5px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: var(--shade-200);
  backdrop-filter: blur(40px);
  .image {
    width: 100%;
    height: 200px;
    max-width: 100%;
    max-height: 200px;
    border-radius: 16px;
    background-color: white;
    border-radius: 16px;
    object-fit: cover;
  }
  .data {
    display: flex;
    padding: 16px 10px 10px 10px;
    flex-direction: column;
    gap: 16px;

    &-top {
      @extend .size1-5-2-S;
      font-weight: 700 !important;
      line-height: 18px !important;
      color: var(--primary);
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: var(--primary-20);
      width: fit-content;

      .ellipse {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: var(--primary);
      }
    }

    &-center {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        @extend .size2-5-2-B;
        color: var(--shade-0);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        @extend .size1-5-R;
        color: var(--shade-100);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 31px !important;
        min-height: 14px !important;
        height: 25px;
      }
    }

    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .price {
        @extend .size2-5-2-B;
        color: var(--shade-0);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        gap: 4px;
        align-items: center;

        .currency {
          @extend .size1-5-2-S;
          font-weight: 700 !important;
          line-height: normal !important;
          color: var(--primary);
        }
      }

      .add {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: var(--primary);
        border: none;
      }

      .increment {
        display: flex;
        align-items: center;
        border-radius: 5px;
        height: 40px;

        &-left {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &-right {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }

        &-right,
        &-left {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--primary);
          border: none;
          width: 40px;
          height: 100%;
        }
        &-center {
          @extend .size2-5-2-B;
          color: var(--shade-0);
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--shade-150);
          border: none;
          width: 39px;
          height: 100%;
        }
      }
    }
  }
}
