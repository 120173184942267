@import "../../../Themes/Font.scss";

.card {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--shade-400);

  &:hover {
    background: var(--shade-200);
  }

  .left {
    display: flex;
    width: 220.5px;
    align-items: center;
    gap: 20px;

    &-span {
      @extend .size1-5-1-M;
      color: var(--shade-0);
      display: flex;
      width: fit-content;
      max-width: 104px;
      flex-direction: column;
      gap: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .label {
        @extend .size0-5-S;
        color: var(--shade-100);
        text-align: left;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 20px;
    .price {
      @extend .size2-R;
      font-size: 16px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 36px !important;
      color: var(--shade-0);
      .bold {
        font-weight: 700 !important;
      }
    }
    .btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 5px;
      background: transparent;

      &:hover {
        background: var(--shade-300);
      }
    }
  }
}

@media screen and (max-width: 455px) {
  .card {
    .left {
      width: fit-content;
      gap: 10px;
    }
    .right {
      gap: 4px;
      .price {
        font-size: 12px !important;
      }
    }
  }
}
