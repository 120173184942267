@import "../../Themes/Font.scss";
.main {
  @extend .size3-R;
  color: var(--shade-0);
  padding: var(--space-10) var(--space-24);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  text-wrap: nowrap !important;
  // text-transform: capitalize;
  &.grey {
    background: var(--shade-200);
    color: var(--shade-0);
    svg {
      fill: none !important;
      stroke: none !important;
      path {
        fill: var(--shade-0);
      }
    }
  }
  &.primary-text {
    background: var(--shade-200);
    border: 1px solid var(--shade-150);
    color: var(--primary);
    text-wrap: nowrap !important;
    svg {
      fill: none !important;
      stroke: none !important;
      path {
        fill: var(--primary);
      }
    }
  }
  &.dashed {
    border: 2px dashed var(--shade-200);
    background: var(--shade-300);
    color: var(--shade-0);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--shade-100);
      path {
        fill: var(--shade-100);
      }
    }
  }
  &.completed {
    background-color: var(--completed);
    color: var(--fixed-white);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--fixed-white);
      path {
        fill: var(--fixed-white);
      }
    }
  }
  &.primary-opacity {
    background: var(--primary-20);
    color: var(--primary);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--primary);
      path {
        fill: var(--primary);
      }
    }
  }
  &.completed-opacity {
    background-color: var(--completed-20);
    color: var(--completed);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--fixed-white);
      path {
        fill: var(--fixed-white);
      }
    }
  }
  &.error {
    background-color: var(--error);
    color: var(--fixed-white);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--fixed-white);
      path {
        fill: var(--fixed-white);
      }
    }
  }
  &.error-opacity {
    background: rgba(251, 81, 88, 0.1);
    color: var(--error);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--error);
      path {
        fill: var(--error);
      }
    }
  }
  &.success {
    background-color: var(--success);
    color: var(--fixed-white);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--fixed-white);
      path {
        fill: var(--fixed-white);
      }
    }
  }
  &.warning {
    background-color: var(--warning);
    color: var(--fixed-white);
    text-wrap: nowrap !important;
    svg {
      stroke: var(--fixed-white);
      path {
        fill: var(--fixed-white);
      }
    }
  }
  &.disabled {
    background-color: var(--shade-150);
    color: var(--shade-100);
    text-wrap: nowrap !important;
    cursor: not-allowed;
    svg {
      stroke: var(--shade-100);
      path {
        fill: var(--shade-100);
      }
    }
  }
  &.orange {
    background-color: #ff9500;
    color: var(--fixed-white);
    text-wrap: nowrap !important;
    // svg {
    //   stroke: var(--fixed-white);
    //   path {
    //     fill: var(--shade-100);
    //   }
    // }
  }
}
.label {
  min-width: fit-content;
}

@media screen and (max-width: 340px) {
  .main {
    font-size: 14px !important;
    text-wrap: nowrap !important;
  }
}
