@import "../../Themes/Font.scss";

.form {
  display: flex;
  width: fit-content;
  padding: 40px 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  background: var(--shade-300);
  margin: 0 auto;
  // min-height: 80vh;
  &::-webkit-scrollbar {
    width: 8px;
    background: var(--shade-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--shade-400);
    border-radius: 5px;
    cursor: pointer;
  }

  .header {
    display: flex;
    padding: 6px 0;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    width: 100%;

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 24px;
      background: var(--shade-200);
      width: 24px;
      height: 24px;
      border: none;
      z-index: 5;
    }

    .title {
      @extend .size5-R;
      line-height: normal !important;
      font-weight: 400 !important;
      color: var(--shade-0);
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -34px;
      z-index: 1;
    }
  }
  .title-main {
    @extend .size5-5-1-S;
    line-height: normal !important;
    color: var(--shade-0);
  }
}

@media screen and (max-width: 620px) {
  .form {
    width: 100% !important;
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 454px) {
  .form {
    .header {
      .title {
        font-size: 20px !important;
      }
    }
  }
}
