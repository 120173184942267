@import "../../../../Themes/Font.scss";
.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  background: var(--shade-150);
  border: 1px solid var(--shade-150);
  transition: all 0.3s ease;
  width: 100% !important;
  height: 45px;
  max-height: 45px;
  box-sizing: border-box;
  &.active {
    background: var(--shade-400) !important;
    border: 1px solid var(--completed);
    padding: 15px;
    outline: 4px solid var(--completed-20);
    svg {
      path {
        fill: var(--shade-0);
      }
    }
  }
  &.error {
    background: var(--shade-400) !important;
    border: 1px solid var(--error);
    padding: 15px;
    outline: 4px solid rgba(251, 81, 88, 0.1);
  }
  svg {
    fill: var(--shade-100);
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    max-height: 14px;
  }
  .input {
    @extend .size2-R;
    background: unset;
    color: var(--shade-0);
    border: none;
    outline: none;
    width: 100%;
    &:disabled {
      color: var(--shade-100);
    }
    &::placeholder {
      color: var(--shade-100);
    }
  }

  // input[type="number"] {
  //   width: 9px;
  //   margin: 0 auto;
  //   text-align: center !important;
  //   -moz-appearance: textfield;
  //   appearance: textfield;
  // }
  &::-webkit-inner-spin-button,
  .number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
label {
  @extend .size2-R;
  color: var(--shade-100);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: var(--shade-0) !important;
  transition: background-color 5000s ease-in-out 0s !important;
  box-shadow: inset 0 0 20px 20px var(--shade-400) !important;
}

input:-webkit-autofill {
  box-shadow: inset 0 0 20px 20px var(--shade-200) !important;
}
