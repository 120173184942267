.link {
  max-width: fit-content;
  // max-height: 24px;
}

@media screen and (max-width: 605px) {
  .logo {
    width: 119px;
  }
}
