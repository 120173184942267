@import "../../../../Themes/Font.scss";

.value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  background: var(--shade-150);
  border: 1px solid var(--shade-150);
  transition: all 0.3s ease;
  width: 100% !important;
  height: 45px;
  max-height: 45px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 14px;
  &.active {
    background: var(--shade-400) !important;
    border: 1px solid var(--completed);
    padding: 15px;
    outline: 4px solid var(--completed-20);
    svg {
      path {
        fill: var(--shade-0);
      }
    }
  }
  &.error {
    background: var(--shade-400) !important;
    border: 1px solid var(--error);
    padding: 15px;
    outline: 4px solid rgba(251, 81, 88, 0.1);
  }
  .left {
    display: flex;
    align-items: center;
    gap: 10px;
    @extend .size2-R;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    width: 100%;
    color: var(--shade-0);
    .dynamic-text {
      @extend .size2-R;
      color: var(--shade-0);
      width: 100%;
      display: block;
      outline: none;
      max-width: 297.7px;
      overflow: hidden;
      text-wrap: nowrap;
      background-color: transparent;
      border: none;
      outline: none;
      -moz-appearance: textfield !important;
      appearance: textfield !important;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &::placeholder {
        color: var(--shade-100) !important;
      }
    }
  }
}
