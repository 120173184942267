@import "../../../../Themes/Font.scss";
.rt-main {
  background-color: var(--shade-200);
  border-radius: 8px;
  height: 100%;
  .editor {
    color: var(--shade-0);
    @extend .size2-R;
  }
}
