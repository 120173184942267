@import "../../Components/Themes/Font.scss";

.dashboard {
  width: 100%;
  display: grid;

  &-card {
    position: relative;
    align-self: center;
    justify-self: center;
    display: flex;
    width: 549px;
    padding: 40px 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    background: var(--shade-300);

    .go-live-btn {
      @extend .size2-R;
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 100% !important;
      color: var(--fixed-white);
      position: absolute;
      top: 24px;
      left: 24px;
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: var(--awa-green);
      border: none;
    }
    .topButtons {
      position: absolute;
      display: inline-flex;
      justify-content: end;
      align-items: flex-end;
      width: 100%;
      padding-right: 24px;
      gap: 20px;
      .edit-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        flex-shrink: 0;
        background-color: white;
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
        border: none;
      }
      .share-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        padding: 16px 16px;
        height: 40px;
        border-radius: 40px;
        outline: 1px solid white;
        color: #fff;
        background: none;
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
        border: none;
        &:hover {
          color: #fff;
          background: rgba(255, 255, 255, 0.1);
        }
      }
      @media screen and (max-width: 532px) {
        position: relative;
        padding-right: 0;
      }
    }
    .edit-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      flex-shrink: 0;
      background-color: white;
      filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.1));
      border: none;
    }

    .avatar-container {
      position: relative;
      width: 150px;
      height: 150px;
      flex-shrink: 0;
      border-radius: 150px;
      z-index: 1;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 150px;
      }
    }

    .title {
      @extend .size5-5-2-B;
      color: var(--shade-0);
    }

    .unverified-prompt {
      @extend .size0-R;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
      color: var(--warning);
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: -14px;
    }

    .add-descriprion-btn {
      @extend .size2-R;
      color: var(--shade-0);
      display: flex;
      padding: 14px 16px;
      justify-content: center;
      align-items: center;
      gap: 14px;
      width: 100%;
      border-radius: 8px;
      border: 2px dashed var(--shade-200);
      background: var(--shade-300);
    }
    .subtitle {
      @extend .size2-R;
      color: var(--shade-100);
      text-align: center;
      width: 402px;
    }
    .sales {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 24px;

      &__tab {
        display: flex;
        height: 70px;
        width: 50%;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid var(--shade-150);
        background: var(--shade-400);
        box-shadow: 0px 25px 40px 0px rgba(130, 124, 241, 0.05);

        .left {
          @extend .size2-5-M;
          color: var(--shade-0);
          display: flex;
          flex-direction: column;
          gap: 4px;

          .label {
            @extend .size1-5-R;
            color: var(--shade-100);
          }
        }

        &-btn {
          display: flex;
          width: 30px;
          padding: 10px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: rgba(133, 215, 185, 0.1);
          border: none;
        }
        &-btn-right {
          @extend .size2-R;
          font-weight: 400 !important;
          line-height: 100% !important;
          color: white;
          display: flex;
          padding: 8px 10px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #f4a622;
          border: none;
        }
      }
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 10px;

      &__btns {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        flex-shrink: 0;
        background: var(--shade-200);
        border: none;
      }
    }
  }
}

.add-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .products-header {
    @extend .size5-5-2-B;
    color: var(--shade-0);
  }
  .product-btn__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
    background: var(--primary);
    border: none;
  }
}

.product-btn {
  @extend .size2-R;
  color: var(--shade-0);
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  width: 100%;

  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    flex-shrink: 0;
    background: var(--primary);
  }
}

.mini-product-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-height: 318px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background: var(--shade-400 !important);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5px;
    cursor: pointer;
  }
}

.awashop-link-container {
  display: flex;
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--shade-150);
  background: var(--shade-200);
  width: 278px;

  .awashop-link {
    @extend .size2-R;
    color: var(--shade-100);
    max-width: 216px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .slug {
      color: var(--shade-0) !important;
    }
  }
}

.flex-col-8 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.variant-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: var(--shade-400);
  .create-variant-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.warning {
  display: flex;
  align-items: flex-start;
  color: var(--shade-0);
  gap: 10px;
  > svg {
    min-width: 24px !important;
  }
}

@media screen and (max-width: 1000px) {
  .mini-product-container {
    max-height: none !important;
  }
}

@media screen and (max-width: 1000px) {
  .dashboard {
    &-card {
      .mini-product-container {
        max-height: none !important;
      }
      .go-live-btn {
        transform: scale(0.7);
      }
    }
  }
}

@media screen and (max-width: 605px) {
  .dashboard {
    &-card {
      width: 100vw;
      border-radius: 0;
      .avatar-container {
        width: 80px;
        height: 80px;
        border-radius: 80px;
      }
      .sales {
        flex-direction: column;

        &__tab {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 555px) {
  .add-product {
    .product-btn {
      padding: 40px 0 !important;
    }
  }
}

@media screen and (max-width: 470px) {
  .dashboard {
    justify-content: center;
    &-card {
      .subtitle {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 532px) {
  .dashboard {
    .product-btn {
      padding: 40px 0 !important;
    }
  }
}
