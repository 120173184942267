@import "../../../Components/Themes/Font.scss";
.main {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--shade-400);
  color: var(--shade-0);
  .button {
    position: absolute;
    top: 20%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 60%;
    outline: none;
    border: none;
    background-color: var(--shade-200);
    transition: all 0.6s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: var(--shade-150);
      svg {
        path {
          fill: var(--shade-0) !important;
        }
      }
    }
  }
  .container {
    width: calc(100vw - (100vw * 0.5618055556));
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    > img {
      height: 40px;
      width: max-content;
    }
    .welcome {
      @extend .size5-R;
      display: flex;
      flex-direction: column;
      gap: 10px;
      strong {
        @extend .size5-5-2-B;
      }
    }
    .introduction {
      @extend .size3-R;
      color: var(--shade-100);
    }
    .checkbox-container {
      display: flex;
      gap: 8px;
      align-items: center;
      min-width: 407.92px;
      .icon {
        display: flex;
        padding: 3px;
        border-radius: 4px;
        background: var(--shade-150);
        min-width: 15.14px;
        min-height: 15.14px;
        cursor: pointer;
        &.checked {
          border: 1px solid var(--awa-green);
          background: var(--shade-400);
        }
      }
      p {
        @extend .size3-R;
        color: var(--shade-100);
        &.active {
          color: var(--shade-0);
        }
      }
    }
    .actions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--space-4);
      > span {
        @extend .size1-L;
        color: var(--shade-0);
      }
    }
  }
}

@media screen and (max-width: 928px) {
  .main {
    .button {
      top: 3% !important;
      left: 3% !important;
    }
  }
}

@media screen and (max-width: 560px) {
  .main {
    .container {
      width: 90% !important;
    }
    .checkbox-container {
      min-width: 100% !important;
    }
  }
}
