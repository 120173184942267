@import "../../Themes/Font.scss";
.toast {
  background-color: var(--shade-0);
  // display: flex;
  width: 100%;
  left: 0 !important;
  padding: 14px;
  border-left-width: 2px;
  border-left-style: solid;
  gap: 8px;
  position: fixed;
  display: flex;
  // opacity: 0;
  top: 0 !important;
  transform: translateY(-100px);
  transition: all 0.9s ease-in-out;
  z-index: 90;
  justify-content: center;
  align-items: center;
  &.active {
    // opacity: 1;
    // transition: all 0.5s ease-in-out;
    top: 100px !important;
  }
  .icon {
    @extend .size3-5-M;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 379.55px;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;

    .heading {
      @extend .size3-5-M;
    }
    .message {
      @extend .size1-5-R;
      color: var(--shade-100);
      @media (max-width: 700px) {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }
    }
  }
  &.active {
    opacity: 1;
    transition: opacity 1s;
  }
  &.success {
    border-left-color: var(--awa-green);
    .info {
      .heading {
        color: var(--awa-green);
      }
    }
  }
  &.warning {
    border-left-color: var(--warning);
    .info {
      .heading {
        color: var(--warning);
      }
    }
  }
  &.error {
    border-left-color: var(--error);
    .info {
      .heading {
        color: var(--error);
      }
    }
  }
  // &.top-right {
  //   top: 100px;
  //   right: 40px;
  // }
  // &.top-left {
  //   top: 100px;
  //   left: 40px;
  // }
  // &.bottom-left {
  //   bottom: 180px;
  //   left: 40px;
  // }
  // &.bottom-right {
  //   bottom: 180px;
  //   right: 40px;
  // }
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-100px);
  }
}

.toast.active {
  animation: slideIn 0.9s forwards;
}

.toast:not(.active) {
  animation: slideOut 0.9s forwards;
}

@media screen and (max-width: 605px) {
  @keyframes slideIn {
    from {
      transform: translateY(-100px);
    }
    to {
      transform: translateY(-22px);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(-22px);
    }
    to {
      transform: translateY(-100px);
    }
  }
}
