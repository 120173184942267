@import "../../../../Themes/Font.scss";
.personalizationOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px 10px 10px !important;
  width: 100%;
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 10px;
    justify-content: center;
    max-width: fit-content;
    min-width: 36px;
    height: 25px;
    background: var(--shade-400);
    border-radius: 8px;
    border: none;
    outline: none;
    span {
      display: flex;
      flex-direction: column;
      margin-top: -2px;
    }
    svg {
      width: 10px !important;
      height: 10px !important;
      min-width: 10px !important;
    }
    ul {
      position: absolute;
      top: 24px;
      left: 0;
      background-color: var(--shade-300);
      padding: 4px;
      height: 30px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 1rem;
        width: 0.5rem;
      }

      &::-webkit-scrollbar:horizontal {
        height: 0.5rem;
        width: 1rem;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 9999px;
      }

      &::-webkit-scrollbar-thumb {
        --tw-border-opacity: 1;
        background-color: #002240;
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
      }

      &::-webkit-scrollbar-thumb:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
      }

      &::-webkit-scrollbar-thumb {
        --tw-bg-opacity: 1;
        background-color: var(--primary);
      }

      &::-webkit-scrollbar-thumb:hover {
        --tw-bg-opacity: 1;
        background-color: var(--primary);
      }

      li {
        @extend .size2-R;
        color: var(--shade-0);
        text-decoration: none;
        list-style-type: none;

        border-bottom: 1px solid var(--shade-100);
        padding: 4px 0;
        cursor: pointer;
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background-color: var(--primary);
        }
      }
    }
  }
  input[type="number"] {
    @extend .size2-R;
    color: var(--shade-0);
    max-width: 30px;
    width: 17px;
    height: 17px;
    max-height: 17px;
    min-height: 17px;
    text-align: center;
    background-color: var(--shade-400);
    border: none;
    outline: none;
    -moz-appearance: textfield !important; /* Firefox */
    appearance: textfield !important;
  }
  &::-webkit-inner-spin-button,
  .number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  p {
    @extend .size2-R;
    color: var(--shade-0);
  }
  .bill {
    @extend .size2-R;
    color: var(--shade-100);
  }
}
