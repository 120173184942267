* {
  margin: 0px;
  box-sizing: border-box;
}

// body {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   /* background: #ddd; */
// }

.editor-wrapper {
  width: 768px;
  border: 1px solid black;
  background: #fff;
  padding: 1rem;
}

.editor-container {
  width: 100%;
  padding: 14px;
}

.toolbar-grid {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 1fr 1fr;
}

// button {
//   margin-right: 8px;
//   font-size: 1rem;
//   border: none;
//   cursor: pointer;
// }

.superFancyBlockquote {
  color: #999;
  background: #fff;
  font-family: "Hoefler Text", Georgia, serif;
  font-style: italic;
  border-left: 2px solid #999;
  padding-left: 10px;
}

.codeBlock {
  font-family: fira-code, monospace;
  font-size: inherit;
  background: #ffeff0;
  font-style: italic;
  word-wrap: normal;
  word-wrap: break-word;
  box-decoration-break: slice;
  padding: 0.3rem 2rem;
  border-radius: 0.2rem;
}

.leftAlign {
  text-align: left;
}
.rightAlign {
  text-align: right;
}
.centerAlign {
  text-align: center;
}
.justifyAlign {
  text-align: justify;
}

.editor-container {
  .DraftEditor-root {
    position: relative;
    .public-DraftEditorPlaceholder-root {
      .public-DraftEditorPlaceholder-inner {
        position: absolute;
      }
    }
    .DraftEditor-editorContainer {
      .public-DraftEditor-content > div {
        figure {
          width: 100%;
          img {
            max-width: 100%;
            border-radius: 8px;
          }
        }
        ul {
          margin-left: 24px !important;
        }
      }
    }
  }
}
