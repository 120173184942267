@import "../../../Components/Themes/Font.scss";
.categories {
  min-height: 100vh;
  min-width: 100%;
  .title-main {
    @extend .size5-5-1-S;
    line-height: normal !important;
    color: var(--shade-0);
  }
  .top {
    display: flex;
    align-items: center;
    gap: 65.5%;
  }
  .categories-container,
  .categories-container2 {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &_left {
      width: 80%;
    }
    &_right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 20%;
      > span {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 20%;
      }

      .actions-btn {
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--shade-200);
        border: none;
        outline: none;
        &:hover {
          background: var(--shade-150);
        }
        &:disabled {
          cursor: not-allowed;
          > svg {
            cursor: not-allowed !important;
            path {
              fill: var(--shade-100) !important;
            }
          }
        }
        &:first-child:enabled {
          background: var(--awa-green) !important;
        }
      }
    }
  }
}

.product-btn {
  @extend .size2-R;
  color: var(--shade-0);
  display: flex;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  max-height: 64px;
  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    flex-shrink: 0;
    background: var(--primary);
  }
}

@media screen and (max-width: 620px) {
  .categories {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 !important;
    }
    .categories-container,
    .categories-container2 {
      gap: 20px !important;
      &_left {
        width: 80% !important;
      }
      &_right {
        width: fit-content !important;
        align-items: flex-end;
        > span {
          width: fit-content !important;
        }
      }
    }
  }
}
