@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 400;
  src: url("./inter/Inter-Regular.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 400;
  src: url("./inter/Inter-Italic.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 100;
  src: url("./inter/Inter-Thin-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 100;
  src: url("./inter/Inter-ThinItalic-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 200;
  src: url("./inter/Inter-ExtraLight-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 200;
  src: url("./inter/Inter-ExtraLightItalic-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  src: url("./inter/Inter-Light-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 300;
  src: url("./inter/Inter-LightItalic-BETA.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 500;
  src: url("./inter/Inter-Medium.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 500;
  src: url("./inter/Inter-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 600;
  src: url("./inter/Inter-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 600;
  src: url("./inter/Inter-SemiBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 700;
  src: url("./inter/Inter-Bold.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 700;
  src: url("./inter/Inter-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 800;
  src: url("./inter/Inter-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 800;
  src: url("./inter/Inter-ExtraBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 900;
  src: url("./inter/Inter-Black.otf") format("opentype");
}
@font-face {
  font-family: inter;
  font-style: italic;
  font-weight: 900;
  src: url("./inter/Inter-BlackItalic.otf") format("opentype");
}
