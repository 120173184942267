* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
  background: var(--shade-300);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #827cf182;
  border-radius: 5px;
  cursor: pointer;
}

body {
  background: var(--shade-400) !important;
}

.svg-indicator {
  width: 166px;
  height: 166px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 
 * Add the center and the radius using the size 
 * and `stroke-width` values.
 */
.svg-indicator-track,
.svg-indicator-indication {
  cx: 83px;
  cy: 83px;
  r: 73px;
  fill: transparent;
  stroke-width: 5px;
}

/* Some color to highlight the rings */
.svg-indicator-track {
  stroke: #ddd;
}

.svg-indicator-indication {
  stroke: #07c;
  stroke-dasharray: 251.2px;
  stroke-dashoffset: -50%;
}

.all {
  display: flex;
}
.circle-wrap {
  transform: rotate(145deg);
  display: grid;
  grid-template-columns: repeat(1, 146px);
  grid-gap: 73px;
  width: 146px;
  height: 146px;
  background: #d9d7da;
  border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill-1,
.circle-wrap .circle .fill-2,
.circle-wrap .circle .fill-3 {
  width: 146px;
  height: 146px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 146px, 146px, 73px);
}

.circle-wrap .inside-circle {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: #212020;
  line-height: 120px;
  text-align: center;
  // margin-top: 13px;
  // margin-left: 13px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-weight: 700;
  font-size: 2em;

  > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(215deg);
    max-width: 54px;
    max-height: 54px;
  }
}

/* color animation */

.mask .fill-1 {
  clip: rect(0px, 73px, 146px, 0px);
  background-color: var(--completed);
}

.mask.full-1,
.circle .fill-1 {
  animation: fill-1 ease-in-out 3s;
  transform: rotate(180deg);
}

.inside-circle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
}

@keyframes fill-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

/* 2nd bar */

.mask .fill-2 {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: yellow;
}

.mask.full-2,
.circle .fill-2 {
  animation: fill-2 ease-in-out 3s;
  transform: rotate(117deg);
}

@keyframes fill-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(117deg);
  }
}

/* 3rd progress bar */
.mask .fill-3 {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: #23b9ea;
}

.mask.full-3,
.circle .fill-3 {
  animation: fill-3 ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
