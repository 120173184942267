@import "../../../Themes/Font.scss";

.cart-item {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: flex-start;
  // justify-content: space-between;
  gap: 20px;
  border-radius: 16px;
  background: var(--shade-200);
  backdrop-filter: blur(40px);

  .product-image {
    width: 154px;
    height: 154px;
    flex-shrink: 0;
    border-radius: 16px;
    background-color: white;
    > img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      min-height: 100%;
      min-width: 100%;
      border-radius: 16px;
      object-fit: cover;
    }
  }
  .data {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-top {
      @extend .size1-5-2-S;
      font-weight: 700 !important;
      line-height: 18px !important;
      color: var(--primary);
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 8px;
      border-radius: 100px;
      background: var(--primary-20);
      width: fit-content;

      .ellipse {
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: var(--primary);
      }
    }
    &-center {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        @extend .size2-5-2-B;
        color: var(--shade-0);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .label {
        @extend .size1-5-R;
        color: var(--shade-100);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 31px !important;
        min-height: 14px !important;
        height: 25.5px;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__left {
        display: flex;
        width: 164.5px;
        flex-direction: column;
        gap: 4px;

        .price {
          display: flex;
          gap: 4px;
          align-items: center;

          > p {
            @extend .size2-5-2-B;
            color: var(--shade-0);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
          }
          .currency {
            @extend .size1-5-2-S;
            font-weight: 700 !important;
            line-height: normal !important;
            color: var(--primary);
          }
        }
        .price-per-item {
          @extend .size1-5-R;
          color: var(--shade-0);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
        }
      }
      .increment {
        display: flex;
        align-items: center;
        border-radius: 5px;
        height: 40px;

        &-left {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &-right {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }

        &-right,
        &-left {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--primary);
          border: none;
          width: 40px;
          height: 100%;
        }
        &-center {
          @extend .size2-5-2-B;
          color: var(--shade-0);
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--shade-150);
          border: none;
          width: 39px;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 531px) {
  .cart-item {
    gap: 10px;

    .product-image {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
      height: 115px;

      > img {
        object-fit: contain;
      }
    }
    .data {
      width: 100%;
    }
  }
}

@media screen and (max-width: 446px) {
  .cart-item {
    .data {
      &-bottom {
        &__left {
          width: 120px;
          max-width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 402px) {
  .cart-item {
    .data {
      &-bottom {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        &__left {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
