.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  background: var(--shade-400);
  overflow: hidden;
}
