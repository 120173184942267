@import "../../../../Themes/Font.scss";

.tag-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px !important;
  width: 100%;
  // min-width: 249px;
  // max-width: fit-content;
  min-height: 45px;
  align-items: center;
  border-radius: 8px;
  background: var(--shade-200);
  cursor: text;
  &-top {
    display: flex;
    padding: 10px 14px 10px 10px;
    width: 100%;
    min-height: 45px;
    max-height: 45px;
    .tag-list {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      width: 100%;
      .tag-item {
        @extend .size2-R;
        list-style-type: none;
        text-decoration: none;
        color: var(--shade-0);
        display: flex;
        padding: 4px 8px;
        justify-self: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--shade-400);
        min-width: fit-content;
      }
      .tag-item-input {
        // width: 130px;
        min-width: 130px;
        list-style-type: none;
        input {
          @extend .size2-R;
          color: var(--shade-100);
          background: var(--shade-200);
          border: none;
          outline: none;
          color: var(--shade-0);
          width: 100%;
          height: 100%;
          &::placeholder {
            @extend .size2-R;
            color: var(--shade-0);
          }
        }
      }
    }
  }
  .suggestions {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    display: flex;
    padding: 14px;
    flex-direction: column;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--shade-200);
    margin-top: 4px;
    z-index: 100;
    max-height: 190px;
    overflow-y: scroll !important;
    box-shadow: 0px 24px 48px 0px rgba(130, 124, 241, 0.07);
    li {
      @extend .size3-R;
      color: var(--shade-100);
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 4px;
      border-radius: 8px;
      list-style-type: none;
      cursor: pointer;
      &:hover {
        background: var(--shade-300);
      }
      .suggestion-avatar {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        object-fit: cover;
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 50px !important;
      min-height: 50px !important;
      background-color: var(--shade-200);
    }

    &::-webkit-scrollbar:horizontal {
      width: 2px;
      height: 50px !important;
      min-height: 50px !important;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--shade-200);
      border-radius: 10px;
      margin-top: 45px !important;
      // margin-left: 10px !important;
    }

    &::-webkit-scrollbar-thumb {
      --tw-border-opacity: 1;
      background-color: #002240;
      border-color: rgba(255, 255, 255, var(--tw-border-opacity));
      border-radius: 10px;
      border-width: 1px;
    }

    &::-webkit-scrollbar-thumb:hover {
      --tw-bg-opacity: 1;
      background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
    }

    &::-webkit-scrollbar-thumb {
      --tw-bg-opacity: 1;
      background-color: var(--shade-400);
      min-height: 50px !important;
      position: absolute !important;
      left: -50% !important;
    }

    &::-webkit-scrollbar-thumb:hover {
      --tw-bg-opacity: 1;
      background-color: var(--shade-400);
    }
  }
}

.tag-input-main {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggestion-avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  object-fit: cover;
}

.cancel {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(251, 81, 88, 0.1);
  border: none;
}

.chevron-down {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  max-height: 14px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 90 !important;
}
