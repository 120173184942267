@import "../../Themes/Font.scss";

.variant-card {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--shade-400);
  width: 100%;

  .left {
    display: flex;
    width: fit-content;
    max-width: 220.5px;
    align-items: center;
    gap: 10px;

    .figure {
      width: 50px;
      height: 50px;
      overflow: hidden;
    }
    &-image {
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;
      min-width: 100%;
      max-width: 100%;
      flex-shrink: 0;
      border-radius: 9px;
      object-fit: cover;
    }

    &-details {
      display: flex;
      flex-direction: column;
      gap: 5px;
      flex-shrink: 0;

      .products-available {
        @extend .size3-R;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
        color: var(--shade-0);
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .product-color {
        width: 10px;
        height: 10px;
        max-height: 10px;
        max-width: 10px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 10px;
      }
      //   .label {
      //     @extend .size0-5-S;
      //     color: var(--shade-100);
      //     max-width: 100%;
      //     width: 100%;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     white-space: nowrap;
      //   }
    }
  }

  .right {
    display: flex;
    align-items: center;
    gap: 20px;

    &-price-container {
      @extend .size1-5-R;
      font-weight: 400 !important;
      color: var(--shade-0);
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 8px;
      .label {
        @extend .size2-5-M;
        color: var(--shade-100);
        width: fit-content;
      }
      .size-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 4px;
        min-height: 23px;
        > span {
          display: flex;
          width: fit-content;
          min-width: 23px;
          max-height: 23px;
          height: 23px;
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: var(--shade-200);
        }
      }
    }

    &-menu-btn {
      position: relative;
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      border-radius: 8px;

      &:hover {
        background-color: var(--shade-300);
      }
    }
  }
}

// @media screen and (max-width: 622px) {
//   .product-card {
//     flex-direction: column;
//     gap: 50px;
//     justify-content: flex-start;
//     align-items: flex-start;
//     .left {
//       width: 100%;
//       justify-content: space-between;
//       min-width: 100%;

//       &-details {
//         align-items: flex-end;
//         width: fit-content;
//       }
//       .label {
//         width: fit-content;
//       }
//     }
//     .right {
//       justify-content: space-between;
//       min-width: 100%;
//       > div {
//         width: 100%;
//       }
//     }
//   }
// }
