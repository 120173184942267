@import "../../Themes/Font.scss";

.dashboard-base {
  position: relative;
  background: var(--shade-400);
  height: fit-content;
  padding: 40px 24px;
  min-height: 100vh;

  .inner {
    width: 100%;
    max-width: 1440px;
    padding: 60px 0 0;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 430px) {
      gap: 0px;
    }

    .warning {
      display: flex;
      padding: 20px;
      margin: 0px auto;
      flex-direction: column;
      gap: 14px;
      border-radius: 9px;
      background: var(--shade-prompt);
      width: 549px;
      z-index: 7;

      .verify-phone {
        @extend .size3-R;
        color: white;
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #f4a622;
        border: none;
        text-decoration: none;
        text-wrap: nowrap !important;
        width: 211px;
      }

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .warning-text {
          @extend .size4-R;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: normal !important;
          color: #ff9500;
        }
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        gap: 14px;
        > li {
          @extend .size3-R;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          display: flex;
          align-items: center;
          gap: 4px;
          text-decoration: none;
          list-style-type: none;
          color: var(--shade-0);
          white-space: nowrap;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px;
            border-radius: 14px;
            background: var(--shade-100);
            min-width: 14px;
            min-height: 14px;
            width: 14px;
            height: 14px;
            // cursor: pointer;
            &.checked {
              background: var(--awa-green);
            }
          }
        }
      }
    }

    .request-live {
      display: flex;
      padding: 20px;
      flex-direction: column;
      gap: 14px;
      border-radius: 9px;
      background: var(--shade-green-prompt);
      width: 549px;
      z-index: 7;

      .request-live-btn {
        @extend .size3-R;
        color: white;
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #10b981;
        border: none;
        text-decoration: none;
        text-wrap: nowrap !important;
        width: 191px;
      }

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .request-live-text {
          @extend .size4-R;
          font-size: 16px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: normal !important;
          color: #10b981;
        }
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        gap: 14px;
        > li {
          @extend .size3-R;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
          display: flex;

          gap: 4px;
          text-decoration: none;
          list-style-type: none;
          color: var(--shade-0);
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px;
            border-radius: 14px;
            background: var(--warning);
            min-width: 14px;
            min-height: 14px;
            width: 14px;
            height: 14px;
            // cursor: pointer;
            &.checked {
              background: var(--awa-green);
            }
          }
        }
      }
    }

    .prompt {
      position: fixed;
      top: 110px;
      display: flex;
      padding: 14px;
      align-items: center;
      gap: 24px;
      justify-content: space-between;
      border-radius: 8px;
      background: var(--shade-prompt);
      z-index: 7;

      &-left {
        @extend .size2-R;
        font-weight: 400 !important;
        line-height: normal !important;
        color: var(--shade-0);
        display: flex;
        align-items: center;
        gap: 10px;
        > svg {
          min-width: 20px;
          min-height: 20px;
        }
      }
      .verify-phone {
        @extend .size3-R;
        color: white;
        display: flex;
        padding: 10px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #f4a622;
        border: none;
        text-decoration: none;
        text-wrap: nowrap !important;
      }
    }
  }
  .main__inner_body {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media screen and (max-width: 605px) {
  .dashboard-base {
    padding: 40px 0;
    .inner {
      padding-top: 40px !important;
    }
    .warning,
    .request-live {
      width: 100% !important;
      border-radius: 0 !important;
    }
    .main__inner_body {
      width: 100% !important;
    }
  }
}

@media screen and (max-width: 698px) {
  .dashboard-base {
    .prompt {
      width: 100% !important;
      border-radius: 0 !important;

      &-left {
        font-size: 12px !important;
      }
      .verify-phone {
        padding-left: 8px !important;
        padding-right: 8px !important;
        font-size: 12px !important;
      }
    }
  }
}

@media screen and (max-width: 364px) {
  .dashboard-base {
    .inner {
      .warning,
      .request-live {
        &__bottom {
          > li {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 454px) {
//   .dashboard-base {
//     .prompt {
//       &-left {
//         text-align: center;
//       }
//       flex-direction: column;
//       gap: 10px !important;
//     }
//   }
// }
