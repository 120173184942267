@import "../../Components/Themes/Font.scss";

.modal {
  display: flex;
  width: 646px;
  max-width: 646px;
  padding: 14px;
  border-radius: 14px !important;
  background: var(--shade-300);
  z-index: 120;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      @extend .size5-R;
      color: var(--shade-0);
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      padding: 8px;
      border-radius: 8px;
      background-color: var(--shade-200);
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .modal-inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;
    background: var(--shade-400);
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;

    &.delete-product {
      gap: 10px !important;
    }
    &::-webkit-scrollbar {
      height: 1rem;
      width: 6px;
      padding-left: 5px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 0.5rem;
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--shade-400);
      border-radius: 9999px;
    }
    &::-webkit-scrollbar-thumb {
      --tw-bg-opacity: 1;
      background-color: var(--shade-200);
    }

    &::-webkit-scrollbar-thumb:hover {
      --tw-bg-opacity: 1;
      background-color: var(--shade-200);
    }
    .top-paragraph {
      @extend .size2-R;
      color: var(--shade-100);
    }

    .date {
      display: flex;
      flex-direction: column;
      width: 283.5px;
      gap: 10px;
    }
    .markdown {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &.triple-height {
        height: 351px;
        min-height: 351px;
      }
    }
  }
  .checkbox-container {
    display: flex;
    gap: 8px;
    align-items: center;
    .icon {
      display: flex;
      padding: 3px;
      border-radius: 4px;
      background: var(--shade-150);
      min-width: 15.14px;
      min-height: 15.14px;
      cursor: pointer;
      &.checked {
        border: 1px solid var(--awa-green);
        background: var(--shade-400);
      }
    }
    p {
      @extend .size3-R;
      color: var(--shade-100);
      &.active {
        color: var(--shade-0);
      }
    }
  }

  .lower-btns {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    > div {
      width: fit-content !important;
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    > label {
      width: 100%;
    }
    > div {
      width: 100%;
    }
  }
}

.modal-center-text {
  @extend .size1-5-R;
  color: var(--shade-100) !important;
  text-align: center !important;
}

.delete-product-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  background-color: var(--shade-150);
  z-index: 110 !important;
  cursor: pointer;
  backdrop-filter: blur(4.5px);
}

.verify-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  z-index: 110 !important;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.75);
}

.payment-iframe {
  min-width: 100%;
  min-height: 100%;
  // overflow-y: hidden !important;
}

@media screen and (max-width: 746px) {
  .modal {
    width: 90%;
  }
}

@media screen and (max-width: 457px) {
  // .modal-inner {
  //   // padding: 0 24px !important;
  // }
  .delete-product-btns,
  .modal-center-text,
  .header {
    padding: 0 24px;
    > h3 {
      font-size: 20px !important;
    }
  }
}
