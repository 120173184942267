@import "../../Themes/Font.scss";

.empty-state {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  img {
    width: 303px;
    height: 108px;
    object-fit: contain;
  }
  &__lower {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      @extend .size3-5-M;
      color: var(--shade-0);
      text-align: center;
    }
    .subtitle {
      @extend .size2-R;
      color: var(--shade-100);
      text-align: center;
      width: 700px;
    }
  }
  .empty-state-btn {
    // max-width: 140px;
    // max-height: 36px;
    align-self: center;
    justify-self: center;
    > div {
      padding: 10px 24px !important;
    }
  }
}
