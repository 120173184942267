@import "../../Themes/Font.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    @extend .size4-R;
    color: var(--shade-100);
    font-weight: 600 !important;
    line-height: 28px !important;
  }
  > li {
    @extend .size3-R;
    color: var(--shade-100);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .price {
      @extend .size4-R;
      font-weight: 400 !important;
      line-height: 28px !important;
      color: var(--shade-0);
      .bold {
        font-weight: 700 !important;
      }
    }
  }
}
.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @extend .size5-R;
  color: var(--shade-0);
  font-weight: 400 !important;
  line-height: 28px !important;
  .total {
    @extend .size5-R;
    color: var(--shade-0);
    font-weight: 400 !important;
    line-height: 36px !important;

    &-bold {
      font-weight: 700 !important;
    }
  }
}

.info {
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid #7b7d7f;
  background: var(--shade-200);

  &-header {
    @extend .size3-R;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
    color: var(--shade-0);
  }
  &-list-container {
    .list {
      @extend .size2-R;
      color: var(--shade-100);
      font-size: 14px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      list-style: none;
      text-decoration: none;
    }
  }
}
