@import "./fonts/fontfaces.scss";

.size5-5-2-B {
  font-family: Inter;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size5-5-1-S {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size5-5-M {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size5-R {
  font-family: Inter;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size4-R {
  font-family: Inter;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size3-5-2-B {
  font-family: Inter;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size3-5-1-S {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size3-5-M {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size3-R {
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size2-5-2-B {
  font-family: Inter;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size2-5-1-S {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size2-5-M {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.placeholder {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
  color: #4b6584;
}

.size2-1-R {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
}

.size2-0-R {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

.size2-R {
  font-family: Inter;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 17px;
  letter-spacing: normal;
}

.size1-5-2-S {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size1-5-1-M {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size1-5-R {
  font-family: Inter;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size1-L {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size0-5-S {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}

.size0-R {
  font-family: Inter;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 100%;
  letter-spacing: normal;
}
