.icon {
  transition: all 0.6s ease-in-out;
  path {
    transition: all 0.6s ease-in-out;
  }
  &.success {
    stroke: var(--success);
    path {
      fill: var(--success);
    }
  }
  &.primary {
    path {
      fill: var(--primary);
    }
  }
  &.error {
    // stroke: var(--error);
    path {
      fill: var(--error);
    }
  }
  &.active {
    stroke: var(--completed);
    path {
      fill: var(--completed);
    }
  }
  &.shade-100 {
    path {
      fill: var(--shade-100);
    }
  }
  &.shade-400 {
    stroke: var(--shade-400);
    path {
      fill: var(--shade-400);
    }
  }
  &.shade-0 {
    // stroke: var(--shade-0);
    path {
      fill: var(--shade-0);
    }
  }
  &.completed {
    path {
      fill: var(--completed);
    }
  }
  &.orange {
    path {
      fill: #ff9500;
    }
  }
  &.white {
    path {
      fill: var(--fixed-white);
    }
  }
  &.black {
    stroke: var(--fixed-black);
    path {
      fill: var(--fixed-black);
    }
  }
  &.spinner {
    animation: rotate 3s linear infinite;
    animation-play-state: running;
  }
  &.awa-green {
    path {
      fill: var(--awa-green);
    }
  }
  &.isGrey {
    mix-blend-mode: luminosity;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
