.toolbar {
  display: flex;
  // padding: 14px;
  align-items: center;
  gap: 0;
  .select {
    > div {
      padding: 14px 10px 14px 14px !important;
    }
  }
  .style-group-container {
    display: flex;
    align-items: center;
    padding: 4px 0;
    .style-group {
      border-left: 1px solid var(--shade-100);
      display: flex;
      gap: 10px;
      padding: 0 10px 0 10px;
      button {
        background-color: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        margin: 0;
        border: none;
        &.active {
          background-color: var(--shade-400);
          outline: 4px solid;
          border-radius: 1px;
        }
      }
    }
  }
}
