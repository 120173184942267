.progressBar-container {
  width: 100%;
  height: 4px;
  background: var(--shade-150);
  border-radius: 40px;
  .progressAnimation {
    height: 4px;
    background: var(--primary);
    border-radius: 40px;
    transition: all 0.3s ease-in;
  }
}

.circular-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
