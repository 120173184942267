@import "../../../Themes/Font.scss";

.card {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  background: var(--shade-200);
  backdrop-filter: blur(40px);

  .image {
    width: 89px;
    height: 89px;
    border-radius: 16px;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 320px;
    width: 320px;
    .title {
      @extend .size2-5-2-B;
      color: var(--shade-0);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 16px !important;
    }

    .desc {
      @extend .size1-5-R;
      color: var(--shade-100);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 31px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 14px !important;
    }
  }
}

.bottom {
  @extend .size2-R;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: var(--shade-0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  width: 100%;

  .price {
    @extend .size2-R;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    color: var(--primary);
    .bold {
      @extend .size2-5-2-B;
      font-weight: 700 !important;
      color: var(--shade-0);
    }
  }
}

@media screen and (max-width: 650px) {
  .card {
    .image {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
    }
  }
}
