@import "../../Components/Themes/Font.scss";

.checkout-screen {
  width: 100%;
  &__inner {
    display: flex;
    flex-direction: column;
    max-width: 1140px;
    margin: 0 auto;

    .header {
      @extend .size5-5-1-S;
      font-size: 36px !important;
      font-weight: 600 !important;
      line-height: 44px !important;
      color: var(--shade-0);
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 24px 0 0 0;
    }
  }
}
