@import "../../../../Themes/Font.scss";

.phone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  min-width: 50%;
  max-height: 45px;
  border-radius: 8px;
  background: var(--shade-200);
  padding: 10px 14px 10px 10px !important;
  .left {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &__countryCode {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 4px;
    width: 84.92px;
    min-width: 84.92px;
    max-width: 84.92px;
    height: 25px;
    background: var(--shade-400);
    border-radius: 8px;
    cursor: pointer;
    &-characters {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        @extend .size2-R;
        color: var(--shade-0);
        max-width: 14px;
        &:first-child {
          max-height: 14px !important;
          overflow-y: hidden;
          //   &::-webkit-scrollbar {
          //     height: 0.5rem;
          //     width: 0.3rem;
          //   }

          //   &::-webkit-scrollbar:horizontal {
          //     height: 0.5rem;
          //     width: 1rem;
          //   }

          //   &::-webkit-scrollbar-track {
          //     background-color: transparent;
          //     border-radius: 9999px;
          //   }

          //   &::-webkit-scrollbar-thumb {
          //     --tw-border-opacity: 1;
          //     background-color: #002240;
          //     border-color: rgba(255, 255, 255, var(--tw-border-opacity));
          //     border-radius: 9999px;
          //     border-width: 1px;
          //   }

          //   &::-webkit-scrollbar-thumb:hover {
          //     --tw-bg-opacity: 1;
          //     background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
          //   }

          //   &::-webkit-scrollbar-thumb {
          //     --tw-bg-opacity: 1;
          //     background-color: var(--primary);
          //   }

          //   &:-webkit-scrollbar-thumb:hover {
          //     --tw-bg-opacity: 1;
          //     background-color: var(--primary);
          //   }
        }
        img {
          width: 14px !important;
          height: 14px !important;
          object-fit: cover;
        }
      }
      .box {
        width: 35px !important;
        max-width: 35px !important;
      }

      input[type="text"] {
        width: 35px !important;
        max-width: 35px !important;
        background-color: var(--shade-300);
        border: none;
        outline: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--shade-0);
        &::placeholder {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: var(--shade-0);
        }
        img {
          width: 14px !important;
          height: 14px !important;
          object-fit: cover;
        }
      }
    }
    &-container {
      position: absolute;
      top: 24px;
      left: 0;
      background-color: var(--shade-200);
      padding: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: var(--shade-0);
      text-decoration: none;
      list-style: none;
      height: 107px;
      overflow-y: scroll;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        border-bottom: 1px solid var(--shade-100);
        padding: 4px 0;
        cursor: pointer;

        img {
          width: 14px !important;
          height: 14px !important;
          object-fit: cover;
        }
        &:hover {
          background-color: var(--primary);
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  input[type="number"] {
    @extend .size2-R;
    background: var(--shade-200);
    color: var(--shade-0);
    border: none;
    outline: none;
    width: fit-content;
    margin: 0;
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;
    width: 102px;
    &::placeholder {
      color: var(--shade-100);
    }
  }
  &::-webkit-inner-spin-button,
  .number-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ul::-webkit-scrollbar {
    height: 1rem;
    width: 0.5rem;
  }

  ul::-webkit-scrollbar:horizontal {
    height: 0.5rem;
    width: 1rem;
  }

  ul::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
  }

  ul::-webkit-scrollbar-thumb {
    --tw-border-opacity: 1;
    background-color: #002240;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
    border-radius: 9999px;
    border-width: 1px;
  }

  ul::-webkit-scrollbar-thumb:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 236, 241, var(--tw-bg-opacity));
  }

  ul::-webkit-scrollbar-thumb {
    --tw-bg-opacity: 1;
    background-color: var(--primary);
  }

  ul::-webkit-scrollbar-thumb:hover {
    --tw-bg-opacity: 1;
    background-color: var(--primary);
  }
}
