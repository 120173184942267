@import "../../../Components/Themes/Font.scss";
.delivery-rates {
  min-height: 100vh;
  min-width: 100%;

  .top {
    display: flex;
    align-items: center;
    gap: 73.5%;
  }
  .tables-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--shade-400);

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 17px;
        height: 17px;
        border-radius: 4px;
        background: var(--shade-200);
        border: none !important;
      }
    }
  }
  .headerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      background: var(--shade-200);
      border: none !important;
    }
  }
  .all-rates-container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--shade-400);
  }
  .categories-container,
  .categories-container2 {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &_left {
      width: 80%;
    }
    &_right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 20%;
      > span {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 20%;
      }

      .actions-btn {
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--shade-200);
        border: none;
        outline: none;
        &:hover {
          background: var(--shade-150);
        }
        &:disabled {
          cursor: not-allowed;
          > svg {
            cursor: not-allowed !important;
            path {
              fill: var(--shade-100) !important;
            }
          }
        }
        &:first-child:enabled {
          background: var(--awa-green) !important;
        }
      }
    }
  }
  .switch-container {
    @extend .size4-R;
    font-weight: 500 !important;
    line-height: normal !important;
    color: var(--shade-0);
    display: flex;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background: var(--shade-400);
    .switch {
      position: relative;
      display: inline-block;
      width: 56px;
      height: 32px;
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(123, 125, 127, 0.05);
        transition: 0.7s;
        border-radius: 100px;
        height: 32px;
        width: 56px;
        &:before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          right: 29px;
          bottom: 6.2px;
          background-color: var(--completed);
          transition: 0.7s;
          border-radius: 60%;
        }
      }
      &.checked {
        .slider {
          background-color: var(--completed-20) !important;
          &::before {
            transform: translateX(23px) !important;
          }
        }
      }
    }
  }
  .title-main {
    @extend .size5-5-1-S;
    line-height: normal !important;
    color: var(--shade-0);
  }
  .rates-container,
  .rates-container2 {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &_left {
      width: 80%;
    }
    &_right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 22%;
      > span {
        display: flex;
        gap: 10px;
        align-items: center;
        width: 20%;
      }

      .actions-btn {
        display: flex;
        padding: 14px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--shade-200);
        border: none;
        outline: none;
        &:hover {
          background: var(--shade-150);
        }
        &:disabled {
          background: var(--shade-200) !important;
          cursor: not-allowed;
          > svg {
            cursor: not-allowed !important;
            path {
              fill: var(--shade-100) !important;
            }
          }
        }
        &:first-child:enabled {
          background: var(--awa-green) !important;
        }
      }
    }
  }
}

.product-btn {
  @extend .size2-R;
  color: var(--shade-0);
  display: flex;
  padding: 20px 0px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 16px;
  border: 1px dashed var(--shade-100);
  background: var(--shade-300);
  cursor: pointer;
  width: 100%;
  text-wrap: nowrap !important;
  white-space: nowrap !important;
  max-height: 64px;
  &:hover {
    background: var(--shade-200);
  }

  &__plus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    flex-shrink: 0;
    background: var(--primary);
  }

  &:disabled {
    // background: var(--shade-100) !important;
    opacity: 0.3;
    cursor: not-allowed;
    > span {
      background: var(--shade-150) !important;
    }
    &:hover {
      background: var(--shade-300);
    }
  }
}

// @media screen and (max-width: 620px) {
//   .rates-container {
//     &_left {
//       width: 90% !important;
//     }
//     &_right {
//       min-height: 170.57px;
//       align-items: flex-end !important;
//       width: 10% !important;
//       > label {
//         width: fit-content;
//       }
//       > span {
//         display: flex;
//         flex-direction: column;
//         gap: 54px !important;
//         align-items: flex-end !important;
//         width: 100% !important;
//       }
//     }
//   }
// }

// @media screen and (max-width: 620px) {
//   .rates-container {
//     &_left {
//       width: 90% !important;
//     }
//     &_right {
//       width: 10% !important;
//     }
//   }
// }

@media screen and (max-width: 620px) {
  .delivery-rates {
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 !important;
    }
    .categories-container,
    .categories-container2 {
      gap: 20px !important;
      &_left {
        width: 80% !important;
      }
      &_right {
        width: fit-content !important;
        align-items: flex-end;
        > span {
          width: fit-content !important;
        }
      }
    }
    .rates-container,
    .rates-container2 {
      background: var(--shade-400) !important;
      align-items: flex-end !important;
      gap: 20px !important;
      padding: 10px;
      border-radius: 16px;
      &_left {
        width: 80% !important;
      }
      &_right {
        width: fit-content !important;
        align-items: flex-start;
        > span {
          width: fit-content !important;
        }
      }
    }
  }
}
