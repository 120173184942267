@import "../Themes/Font.scss";
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 40px 0 40px;
  z-index: 100;
  background: var(--shade-400);
  .hamburger {
    border: none;
    outline: none;
    padding: 10px 16px;
    background: transparent;
  }
  .page {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .title {
      @extend .size5-5-1-S;
      color: var(--shade-0);
    }
    .links {
      color: var(--shade-100);
      display: flex;
      gap: 4px;
      align-items: center;
      .project {
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
        .span {
          @extend .size2-R;
        }
      }
      .active {
        @extend .size2-5-2-B;
      }
    }
  }
  .actions {
    display: flex;
    gap: var(--space-24);
    align-items: center;
    .search {
      position: relative;
      div {
        width: 200px !important;
        height: 40px !important;
        border-radius: 20px !important;
      }
      .global-search {
        position: absolute;
        right: 8%;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        @extend .size0-R;
        color: var(--shade-100);
        display: flex;
        padding: 2px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--shade-150) !important;
        background: transparent;
        &:hover {
          background: var(--shade-300);
        }
      }
    }
    .adder {
      position: relative;
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: var(--primary);
      border-radius: 20px;
      justify-content: center;
      cursor: pointer;
      // z-index: 120 !important;
    }
    .user {
      position: relative;
      display: flex;
      gap: var(--space-14);
      align-items: center;
      border-radius: 20px;
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: var(--shade-200);
      }
      .green-circle {
        position: absolute;
        bottom: 10px;
        left: 38px;
        width: 14px;
        height: 14px;
      }
      > ul {
        left: -20px;
        top: 130%;
      }
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      .data {
        @extend .size1-5-R;
        display: flex;
        flex-direction: column;
        gap: 2px;
        color: var(--shade-100);
        cursor: pointer;
        .name {
          @extend .size2-5-1-S;
          color: var(--shade-0);
        }
        .ownership {
          max-width: 90px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.dropdown-list {
  position: absolute;
  top: 170%;
  right: -75%;
  display: inline-flex;
  padding: 17px;
  flex-direction: column;
  gap: 14px;
  border-radius: 8px;
  background: var(--primary);
  z-index: 120 !important;
  min-width: fit-content;
  width: 230px;
  .polygon {
    position: absolute;
    right: 15%;
    top: -10%;
  }
  &_label {
    @extend .size3-5-2-B;
    color: var(--fixed-white);
  }
  .li {
    @extend .size2-R;
    color: var(--fixed-white);
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.mobile-dropdown {
  top: 79.43px;
  width: 100%;
  min-width: fit-content;
  max-height: calc(100% - 79.43px);
  min-height: 85dvh;
  overflow-y: auto;
  left: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  padding: 24px;
  background: var(--shade-300);
  z-index: 130;
  cursor: pointer;
  &::-webkit-scrollbar {
    width: 8px !important;
    background: var(--shade-200) !important;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--shade-400) !important;
    border-radius: 5px !important;
    cursor: pointer;
  }

  li {
    display: flex;
    padding: 10px 0px;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    min-width: fit-content;
    border-bottom: 1px solid var(--shade-150);
    &:first-child {
      padding-top: 0 !important;
    }
    &:nth-child(3),
    &:nth-child(4) {
      border-bottom: none !important;
    }
    &:nth-child(9) {
      pointer-events: none !important;
      border-bottom: none !important;

      > span {
        padding: 0 !important;
        justify-content: center !important;
        > svg {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    &:last-child {
      padding: 18px 24px !important;
      border-radius: 8px;
      margin-top: 36px;
      border-bottom: none !important;
      background: var(--shade-200) !important;
      > span {
        background: transparent !important;
        color: var(--error) !important;
        padding: 0 !important;
        > svg {
          path {
            fill: var(--error) !important;
          }
        }
      }
      &:hover {
        > span {
          background: transparent !important;
          color: var(--error) !important;
        }
        > span svg {
          // stroke: var(--shade-0) !important;
          path {
            fill: var(--error) !important;
          }
        }
      }
    }
    span {
      @extend .size3-R;
      color: var(--shade-100);
      display: flex;
      gap: 14px;
      align-items: center;
      width: 100%;
      white-space: nowrap !important;
      text-overflow: ellipsis;
      padding: 18px 24px;
    }
    p {
      @extend .size1-L;
      color: var(--success);
    }
    &:hover {
      > span {
        background: var(--shade-400);
        color: var(--shade-0) !important;
        border-radius: 8px;
      }
      > span svg {
        // stroke: var(--shade-0) !important;
        path {
          fill: var(--shade-0) !important;
        }
      }
    }
  }
}

@media screen and (max-width: 605px) {
  .main {
    padding: 18px 16px 18px 16px !important;
    min-width: 100%;
    .data {
      > span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 431px) {
  .main {
    .actions {
      > svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
