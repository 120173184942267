@import "../../../Components/Themes/Font.scss";

.view-products {
  width: 100%;
  background: var(--shade-400);
  min-height: 100vh;
  padding: 40px 24px;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 549px;
    margin: 0 auto;

    .header {
      display: flex;
      padding: 20px;
      align-items: center;
      gap: 10px;
      border-radius: 10px;

      &-left {
        width: 56.195px;
        height: 56.195px;
        min-width: 56.195px;
        min-height: 56.195px;
        border-radius: 56.195px;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 64px;
        }
      }

      &-right {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        overflow: hidden;

        .details {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .business-name {
            @extend .size3-R;
            color: var(--shade-0);
            font-size: 20px !important;
            font-style: normal !important;
            font-weight: 700 !important;
            line-height: 30px !important;
          }
          .icons {
            display: flex;
            align-items: center;
            gap: 4px;

            &__btns {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              background: var(--shade-200);
            }
          }
        }
        .desc {
          @extend .size2-R;
          color: var(--shade-100);
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .main {
      display: flex;
      width: 100%;
      padding: 40px 24px;
      flex-direction: column;
      gap: 24px;
      border-radius: 16px;
      background: var(--shade-300);
      &-header {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        flex-direction: column;
        .shopping-cart-btn {
          position: relative;
          display: flex;
          width: 50px;
          height: 50px;
          justify-content: center;
          align-items: center;
          background: var(--fixed-white);
          border-radius: 50px;
          box-shadow: -10px 4px 34px 0px rgba(255, 255, 255, 0.25);
          border: none;
          flex-shrink: 0;
          .no-in-cart {
            @extend .size2-R;
            font-size: 12px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important;
            color: var(--fixed-white);
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            padding: 2px 4px;
            justify-content: center;
            align-items: center;
            background: var(--primary);
            min-width: 16px;
            min-height: 16px;
            border-radius: 30px;
          }
        }
        .title {
          @extend .size5-5-2-B;
          color: var(--shade-0);
        }
        > span {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          align-items: center;
          > div {
            border-radius: 100px;
            width: 100%;
            > div {
              border-radius: 100px;
              width: 100%;
            }
          }
        }
      }
      .products {
        display: grid;
        position: relative;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 15px;
        width: 100%;
        flex-wrap: wrap;
        min-height: 360px;
        padding-right: 5px;
        cursor: pointer;
        // > div {
        //   max-width: 48% !important;
        // }
        &::-webkit-scrollbar {
          width: 2px;
          background: var(--shade-400 !important);
          cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--primary);
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .footer {
      @extend .size3-R;
      font-size: 16px !important ;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: var(--shade-100);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
    .cart-prompt {
      @extend .size3-R;
      color: var(--fixed-white);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      padding: 14px 24px;
      background: var(--primary);
      position: fixed;
      bottom: -100px;
      transition: all 0.5s ease-in-out;
      border: none;
      &.visible {
        bottom: 0 !important;
      }
      .qty-in-cart {
        @extend .size2-R;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 100% !important;
        color: black;
        display: flex;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        background: var(--fixed-white);
        min-width: 16px;
        min-height: 16px;
        border-radius: 30px;
      }
    }
  }
}

@media screen and (max-width: 606px) {
  .view-products {
    padding: 0px 0px 0px 0px;
    &__inner {
      min-width: 100%;
      max-width: 100%;
      // min-height: 100vh;
      // height: 100vh;
      gap: 0;
      .footer {
        height: 48px;
      }
      .header,
      .main {
        border-radius: 0;
        // .products {
        //   min-height: 100%;
        // }
      }
      // .header {
      //   &-right {
      //     .details {
      //       .icons {
      //         &__btns {
      //           width: 30px !important;
      //           height: 30px !important;
      //           border-radius: 30px !important;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

@media screen and (max-width: 545px) {
  .view-products {
    &__inner {
      .header {
        &-left {
          display: none;
        }
        &-right {
          max-width: 100%;
          .details {
            .icons {
              &__btns {
                width: 30px !important;
                height: 30px !important;
                border-radius: 30px !important;
                > svg {
                  width: 20px !important;
                  height: 20px !important;
                }
              }
            }
            .business-name {
              max-width: calc(100% - 142px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .main {
        &-header {
          > span {
            gap: 24px !important;
          }

          .title {
            display: none;
          }
        }

        .products {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          max-height: 100%;
          overflow: auto;
          > div {
            max-width: 100%;
            min-width: 100%;
            > img {
              max-height: 172.09px;
              min-height: 172.09px;
            }
          }
        }
      }
    }
  }
}
