@import "../../../../Themes/Font.scss";

.date-input {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 283.5px;
  height: 45px;
  padding: 14px;
  text-align: center;
  border-radius: 8px;
  background: var(--shade-200);
  input {
    @extend .size2-R;
    color: var(--shade-100);
    border: none;
    background: none;
    outline: none;
    &::placeholder {
      @extend .size2-R;
      color: var(--shade-100) !important;
    }
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::placeholder {
  @extend .size2-R;
  color: var(--shade-100) !important;
}
.date-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
