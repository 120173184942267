@import "../../../../Themes/Font.scss";
.input-container {
  position: relative;
  background: var(--shade-200);
  width: 100%;
  // min-width: fit-content !important;
  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    // min-width: 140px !important;

    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      height: fit-content;
      max-height: 14px;
    }
    .placeholder {
      @extend .size2-R;
      background: var(--shade-200);
      color: var(--shade-100);
      border: none;
      outline: none;
      // display: flex;
      // align-items: center;
      // gap: 10px;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &::placeholder {
        color: var(--shade-100);
      }
    }
  }
}
.enabled {
  @extend .size2-R;
  color: var(--shade-0) !important;
  // display: flex;
  // align-items: center;
  // gap: 10px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
label {
  @extend .size2-R;
  color: var(--shade-100);
}

.left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-text {
  @extend .size2-R;
}
