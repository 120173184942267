.main {
  width: 1080px;
  height: 1350px;
  margin: 0 auto;
  background: #f5f5f5;
  padding: 50px;
  .inner {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3.75rem 3.75rem 0rem 3.75rem;
    background: url("../../assets/images/share-bg.jpg");
    background-size: cover;
    padding: 50px;
    .top {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      gap: 27.5px;
      .nowOn {
        display: inline-flex;
        height: 3.125rem;
        align-items: center;
        gap: 1.11606rem;
        flex-shrink: 0;
        .divider {
          width: 0.0625rem;
          height: 4.6875rem;
          background: #fff;
        }
        .nowOnText {
          color: #fff;
          text-align: center;
          font-family: Inter;
          font-size: 2.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .nowOnBusiness {
        color: #fff;
        text-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
        font-family: Hanson;
        font-size: 70px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .businessLogo {
      width: 447px;
      height: 447px;
      flex-shrink: 0;
      border-radius: 447px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 0px auto;
      border-radius: 447px;
      box-shadow: 30px -43px 0px 0px rgba(255, 241, 204, 0.17);
    }
    .businessLogo.variant {
      width: 40.8125rem;
      height: 40.8125rem;
      margin: 130px 0 0;
    }
    .cta {
      position: absolute;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
      bottom: 50px;
      right: 50px;
      button {
        display: flex;
        padding: 16px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 70px;
        border: 1px solid;
        border-color: linear-gradient(
          270deg,
          #000 -20.5%,
          #dbdbdb 14.27%,
          #313131 56.99%,
          #fff 87.04%
        );
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
        font-family: Inter;
        font-size: 26.43px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      p {
        color: rgba(255, 255, 255, 0.8);
        text-align: right;
        font-family: Inter;
        font-size: 26.43px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: #fff;
          font-family: Inter;
          font-size: 26.43px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
