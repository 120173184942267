@import "../../Themes/Font.scss";

.cart-header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  h3 {
    color: var(--shade-0);
  }
  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
  &__tabs {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 37px;
    cursor: pointer;
    .titles {
      @extend .size2-5-1-S;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      color: var(--shade-100);
    }
    &.active {
      > .titles {
        color: var(--shade-0) !important;
      }
    }
  }
  &__slider-span {
    position: absolute;
    bottom: 2px;
    display: block;
    height: 1px;
    background-color: var(--primary);
    transition: 0.7s transform ease;
  }
  &__slider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
  }
}
