@import "../../../Components/Themes/Font.scss";

.close {
  width: 100%;
  min-height: 100vh;
  background: var(--shade-400);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;

  .header {
    @extend .size3-R;
    font-size: 24px !important;
    color: var(--shade-0);
    text-align: center;
  }

  .div {
    width: 120px;
  }
}
