@import "../../../Components/Themes/Font.scss";
.checkout {
  width: 100%;
  background: var(--shade-400);
  min-height: 100vh;

  padding: 40px 0px;

  .title-main {
    @extend .size5-5-1-S;
    line-height: normal !important;
    color: var(--shade-0);
  }
  .payment-btn-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .payment-btn {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 16px;
    background: var(--shade-150);
    border: none;

    &.active {
      border: 1px solid #fff;
    }

    .check-circle {
      display: flex;
      width: 20px;
      height: 20px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #fff;
      background: #fff;

      > i {
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--primary);
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .payment-mode {
        @extend .size3-R;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
        color: var(--shade-0);
      }

      .powered-by {
        display: flex;
        align-items: center;
        gap: 8px;
        @extend .size2-R;
        color: var(--shade-100);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .checkout {
    padding: 0 !important;
  }
}
