@import "../../../Components/Themes/Font.scss";

.update-product {
  width: 100%;
  .btn-bg {
    width: 100%;
    height: 157px;
  }
  .lower-btns {
    display: flex;
    gap: 16px;
  }
  .product-btn {
    @extend .size2-R;
    color: var(--shade-0);
    display: flex;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border-radius: 16px;
    border: 1px dashed var(--shade-100);
    background: var(--shade-300);
    cursor: pointer;
    width: 100%;
    text-wrap: nowrap !important;
    white-space: nowrap !important;

    &:hover {
      background: var(--shade-200);
    }

    &__plus {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      flex-shrink: 0;
      background: var(--primary);
    }
  }
  .flex-col-8 {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .variant-container {
    display: flex;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: var(--shade-400);
    .create-variant-btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 411px) {
  .update-product {
    .product-btn {
      padding: 40px 0 !important;
    }
  }
}
