@import "../../Themes/Font.scss";

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 4px;
  padding: 8px;
  background: var(--shade-200);
  border-radius: 8px;
  z-index: 130;
  cursor: pointer;
  &::-webkit-scrollbar {
    width: 8px;
    background: var(--shade-200);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--shade-400);
    border-radius: 5px;
    cursor: pointer;
  }

  &.list-item-actions {
    top: 0;
    width: 138px;
    top: 17px;
    right: 0;
    li {
      @extend .size2-R;
      color: var(--shade-100);
      display: flex;
      padding: 8px 14px;
      align-items: center;
      gap: 14px;
      align-self: stretch;
      border-radius: 8px;
      &:hover {
        background: var(--shade-300);
      }
    }
  }
  &.projects {
    top: 0;
    width: 264px;
    li {
      display: flex;
      padding: 14px 16px;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      border-radius: 8px;
      span {
        @extend .size3-R;
        color: var(--shade-100);
        display: flex;
        gap: 14px;
        align-items: center;
      }
      p {
        @extend .size1-L;
        color: var(--success);
      }
      &:hover {
        background: var(--shade-300);
      }
    }
  }
  &.accounts {
    margin-top: 8px;
    width: 100%;
    li {
      display: flex;
      padding: 14px 16px;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      border-radius: 8px;
      span {
        @extend .size3-R;
        color: var(--shade-100);
        display: flex;
        gap: 14px;
        align-items: center;
        .company-logo {
          max-width: 14px;
          max-height: 14px;
          min-width: 14px;
          min-height: 14px;
          object-fit: cover;
        }
      }
      p {
        @extend .size1-L;
        color: var(--success);
      }
      &:hover {
        background: var(--shade-300);
      }
    }
    button {
      @extend .size2-R;
      color: var(--shade-100);
      position: relative;
      display: flex;
      padding: 8px 14px;
      justify-content: center;
      align-items: center;
      gap: 14px;
      align-self: stretch;
      border-radius: 8px;
      outline: none;
      border: none;
      background: transparent;
      margin-top: 4px;
      &::before {
        position: absolute;
        content: "";
        top: -4px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: var(--shade-150);
      }
      &:hover {
        background: var(--shade-300);
      }
    }
  }
  &.task-status {
    top: 32px;
    right: 0;
    width: 119px;

    p {
      @extend .size1-5-R;
      color: var(--shade-0);
    }
    li {
      @extend .size1-5-R;
      display: flex;
      padding: 4px 8px;
      &.list-item1 {
        color: var(--shade-0);
        background-color: var(--shade-150);
      }
      &.list-item2 {
        color: var(--shade-0);
        background: rgba(133, 215, 185, 0.2);
      }
      &.list-item3 {
        color: var(--completed);
        background-color: var(--completed-20);
      }
      &.list-item4 {
        color: var(--awa-green);
        background: rgba(133, 215, 185, 0.2);
      }
    }
  }
  &.regular {
    top: 110%;
    width: 100%;
    min-width: fit-content;
    max-height: 204px;
    overflow-y: auto;
    left: 0;
    .avatar {
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      max-height: 24px;
      min-height: 24px;
      border-radius: 24px;
      object-fit: cover;
    }
    li {
      display: flex;
      padding: 14px 16px;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      border-radius: 8px;
      min-width: fit-content;
      span {
        @extend .size3-R;
        color: var(--shade-100);
        display: flex;
        gap: 14px;
        align-items: center;
        min-width: fit-content;
        white-space: nowrap !important;
        text-overflow: ellipsis;
      }
      p {
        @extend .size1-L;
        color: var(--success);
      }
      &:hover {
        background: var(--shade-300);
        > span {
          color: var(--shade-0) !important;
        }
        > span svg {
          stroke: var(--shade-0) !important;
          path {
            fill: var(--shade-0) !important;
          }
        }
      }
    }
  }
  &.task-priorities {
    width: 100%;
    gap: 10px !important;
    left: 0;
    top: 60px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 120 !important;
  cursor: pointer;
  &.overlay {
    background: var(--shade-150);
    backdrop-filter: blur(4.5px);
  }
}
